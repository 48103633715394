import React from "react";
import copy from "../../ui/Assets/Icons/copy-results.png";
import download from "../../ui/Assets/Icons/download.png";
import CopyToClipboard from "react-copy-to-clipboard";
import classes from "./backendDataInfoPage.module.css";
import JSZip from "jszip";
import { selectEmptyFields } from "./backendInfoSlice";
import { selectServiceGroup } from "./serviceGroupSlice";
import {
  selectIsLoadingDataVehicleClaims,
  selectVehicleClaims,
} from "./vehicleClaimsSlice";
import {
  selectIsLoadingDataActivationJobs,
  selectVehicleJobs,
} from "./activationJobsSlice";
import {
  selectAllVehicleServiceClaims,
  selectIsLoadingDataAllVehicleServiceClaims,
} from "./allVehicleServiceClaimsSlice";
import {
  selectIsLoadingDataPrimaryUserClaims,
  selectPrimaryUserClaims,
} from "./primaryUserClaimsSlice";
import {
  selectVehicleCurrentState,
  selectCurrentStateStatus,
  selectCurrentVehicleStatus,
  selectAccessForbiddenToState,
  selectIsLoadingDataVehicleCurrentState,
} from "./vehicleCurrentStateSlice";
import info from "../../ui/Assets/Icons/infoNoBorder.png";

import LoadingSpinner from "../../ui/Loading Spinner/LoadingSpinner";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import {
  selectIsLoadingDataManifest,
  selectIsLoadingDataManifestHistory,
  selectManifest,
  selectManifestHistory,
} from "./cube/cubeSlice";

function JsonProtocols() {
  const vehicleJobs = useSelector(selectVehicleJobs);
  const vehicleClaims = useSelector(selectVehicleClaims);
  const serviceGroup = useSelector(selectServiceGroup);
  const primaryUserClaims = useSelector(selectPrimaryUserClaims);
  const allVehicleServiceClaims = useSelector(selectAllVehicleServiceClaims);
  const vehicleCurrentState = useSelector(selectVehicleCurrentState);
  const currentStateStatus = useSelector(selectCurrentStateStatus);
  const emptyFields = useSelector(selectEmptyFields);
  const currentVehicleStatus = useSelector(selectCurrentVehicleStatus);
  const accessForbidden = useSelector(selectAccessForbiddenToState);
  const isloadingVehicleClaims = useSelector(selectIsLoadingDataVehicleClaims);
  const isLoadingActivationJobs = useSelector(
    selectIsLoadingDataActivationJobs
  );
  const isLoadingVehicleCurrentState = useSelector(
    selectIsLoadingDataVehicleCurrentState
  );
  const isLoadingUserClaimsOfPrimaryUser = useSelector(
    selectIsLoadingDataPrimaryUserClaims
  );
  const isLoadingAllVehicleServiceClaims = useSelector(
    selectIsLoadingDataAllVehicleServiceClaims
  );
  const isLoadingManifestHistory = useSelector(
    selectIsLoadingDataManifestHistory
  );
  const isLoadingManifest = useSelector(selectIsLoadingDataManifest);

  const manifestHistory = useSelector(selectManifestHistory);
  const manifest = useSelector(selectManifest);
  const prettyCopyJson = (data) => {
    return JSON.stringify(data, null, 2);
  };
  const sortManifestsByTimestamp = (manifests) => {
    return manifests
      .slice()
      .sort(
        (a, b) => new Date(a.createdTimestamp) - new Date(b.createdTimestamp)
      );
  };

  const downloadHandler = (textToDownload, fileNameString) => {
    // Get the current date and time
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(now.getDate()).padStart(2, "0");
    const hour = String(now.getHours()).padStart(2, "0");
    const minute = String(now.getMinutes()).padStart(2, "0");
    const second = String(now.getSeconds()).padStart(2, "0");
    // Construct the filename with the current date and time
    const formattedFileName = `${year}${month}${day}_${hour}${minute}${second}_${fileNameString}.json`;
    // Create a Blob from the text content
    const blob = new Blob([textToDownload], { type: "text/plain" });
    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);
    // Create a temporary link element
    const link = document.createElement("a");
    link.href = url;
    link.download = formattedFileName;
    // Trigger a click event on the link to initiate the download
    link.click();
    // Clean up by revoking the URL and removing the link
    window.URL.revokeObjectURL(url);
    // Return the formatted filename
    return `${year}${month}${day}_${hour}${minute}${second}_${fileNameString}.json`;
  };

  const downloadAllHandler = () => {
    const now = new Date(); // Use the moment of the click for all files
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(now.getDate()).padStart(2, "0");
    const hour = String(now.getHours()).padStart(2, "0");
    const minute = String(now.getMinutes()).padStart(2, "0");
    const second = String(now.getSeconds()).padStart(2, "0");

    const file1Text = `${prettyCopyJson(serviceGroup)}.json`;
    const file2Text = `${prettyCopyJson(vehicleJobs)}.json`;
    const file3Text = `${prettyCopyJson(vehicleClaims)}.json`;
    const file4Text = `${prettyCopyJson(allVehicleServiceClaims)}.json`;
    const file5Text = `${prettyCopyJson(primaryUserClaims)}.json`;
    const file6Text = `${prettyCopyJson(vehicleCurrentState)}.json`;

    // Create a zip archive
    const zip = new JSZip();
    zip.file("GetServiceGroup.json", file1Text);
    zip.file("GetVehicleJobs.json", file2Text);
    zip.file("GetVehicleClaims.json", file3Text);
    zip.file("GetAllVehicleServiceClaims.json", file4Text);
    zip.file("GetDefaultServiceVehicleUserClaimsOfPrimaryUser.json", file5Text);
    zip.file("GetVehicleCurrentState.json", file6Text);

    // Generate the zip file and initiate the download
    zip.generateAsync({ type: "blob" }).then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${year}${month}${day}_${hour}${minute}${second}__all_protocols.zip`;
      link.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <>
      <div className={classes.downloadAllButton}>
        <div
          className={classes.downloadAllBtnText}
          onClick={downloadAllHandler}
        >
          Download ALL protocols
        </div>
      </div>
      {/* first table */}
      <div
        className={classes.flexContainerRowDoubleTable}
        style={{ userSelect: "text", cursor: "auto" }}
      >
        <div className={classes.doubleTableSet}>
          <div className={classes.tableDoubleDiv}>
            <div className={classes.tableDoubleDivTitle}>
              {" "}
              <div className={classes.tableDoubleDivTitleText}>
                getVehicleServiceGroup
              </div>
              {/*  */}
              <div className={classes.infoSnackBar}></div>
              <div className={classes.copyIcon}>
                <CopyToClipboard
                  text={` JSON response: ${prettyCopyJson(serviceGroup)}
           `}
                >
                  <img
                    src={copy}
                    alt=""
                    title="Copy service group on clipboard"
                    onClick={() => console.log("copied")}
                  />
                </CopyToClipboard>
              </div>
              <div className={classes.downloadIcon}>
                <img
                  src={download}
                  alt=""
                  title="Download service group response"
                  onClick={() =>
                    downloadHandler(
                      prettyCopyJson(serviceGroup),
                      "_GetServiceGroup"
                    )
                  }
                />
              </div>
              {/*  */}
            </div>
            <div className={classes.tableDoubleDivDescription}>
              {" "}
              ** fetches the selected VIN's service groups **
            </div>
            <div className={classes.tableDoubleDivJson}>
              {serviceGroup.ServiceGroupCode !== undefined && !emptyFields ? (
                <pre>{`${prettyCopyJson(serviceGroup)}`}</pre>
              ) : serviceGroup.ServiceGroupCode === undefined &&
                !emptyFields ? (
                <LoadingSpinner />
              ) : (
                "No data available"
              )}
            </div>
          </div>
          {/* second Table */}
          {/*  */}
          {/*  */}
          <div className={classes.tableDoubleDiv}>
            <div className={classes.tableDoubleDivTitle}>
              <div className={classes.tableDoubleDivTitleText}>
                getVehicleClaims
              </div>
              <div className={classes.infoSnackBar}></div>
              <div className={classes.copyIcon}>
                <CopyToClipboard
                  text={` JSON response: ${prettyCopyJson(vehicleClaims)}
           `}
                >
                  <img
                    src={copy}
                    alt=""
                    title="Copy claims on clipboard"
                    onClick={() => console.log("copied")}
                  />
                </CopyToClipboard>
              </div>
              <div className={classes.downloadIcon}>
                <img
                  src={download}
                  alt=""
                  title="Download vehicle claims response"
                  onClick={() =>
                    downloadHandler(
                      prettyCopyJson(vehicleClaims),
                      "_GetVehicleClaims"
                    )
                  }
                />
              </div>
            </div>
            <div className={classes.tableDoubleDivDescription}>
              {" "}
              ** Fetches vehicle (VIN) claims **
            </div>
            <div className={classes.tableDoubleDivJson}>
              {!isloadingVehicleClaims && !emptyFields ? (
                <pre>{`${prettyCopyJson(vehicleClaims)}`}</pre>
              ) : isloadingVehicleClaims && !emptyFields ? (
                <LoadingSpinner />
              ) : (
                "No data available"
              )}
            </div>
          </div>
          {/*  */}
          {/*  */}
          {/*  */}
        </div>
        {/* ----Start CUBE----- */}
        <div
          className={classes.flexContainerRowDoubleTable}
          style={{ userSelect: "text", cursor: "auto" }}
        >
          <div className={classes.doubleTableSet}>
            <div className={classes.tableDoubleDiv}>
              <div className={classes.tableDoubleDivTitle}>
                {" "}
                <div className={classes.tableDoubleDivTitleText}>
                  getManifestHistory
                </div>
                {/*  */}
                <div className={classes.infoSnackBar}></div>
                <div className={classes.copyIcon}>
                  <CopyToClipboard
                    text={` JSON response: ${prettyCopyJson(manifestHistory)}
           `}
                  >
                    <img
                      src={copy}
                      alt=""
                      title="Copy manifest history on clipboard"
                      onClick={() => console.log("copied")}
                    />
                  </CopyToClipboard>
                </div>
                <div className={classes.downloadIcon}>
                  <img
                    src={download}
                    alt=""
                    title="Download manifest history response"
                    onClick={() =>
                      downloadHandler(
                        prettyCopyJson(manifestHistory),
                        "_GetManifestHistory"
                      )
                    }
                  />
                </div>
                {/*  */}
              </div>
              <div className={classes.tableDoubleDivDescription}>
                {" "}
                ** fetches the selected VIN's manifest history **
              </div>
              <div className={classes.tableDoubleDivJson}>
                {manifestHistory.manifests !== undefined && !emptyFields ? (
                  <>
                    {" "}
                    <pre>
                      {`${prettyCopyJson({
                        manifests: sortManifestsByTimestamp(
                          manifestHistory.manifests
                        ),
                      })}`}
                    </pre>{" "}
                  </>
                ) : manifestHistory.manifests === undefined && !emptyFields ? (
                  <LoadingSpinner />
                ) : (
                  "No data available"
                )}
              </div>
            </div>
            {/* second Table */}
            {/*  */}
            {/*  */}
            <div className={classes.tableDoubleDiv}>
              <div className={classes.tableDoubleDivTitle}>
                <div className={classes.tableDoubleDivTitleText}>
                  getManifest
                </div>
                <div className={classes.infoSnackBar}></div>
                <div className={classes.copyIcon}>
                  <CopyToClipboard
                    text={` JSON response: ${prettyCopyJson(manifest)}
           `}
                  >
                    <img
                      src={copy}
                      alt=""
                      title="Copy claims on clipboard"
                      onClick={() => console.log("copied")}
                    />
                  </CopyToClipboard>
                </div>
                <div className={classes.downloadIcon}>
                  <img
                    src={download}
                    alt=""
                    title="Download vehicle claims response"
                    onClick={() =>
                      downloadHandler(prettyCopyJson(manifest), "_GetManifest")
                    }
                  />
                </div>
              </div>
              <div className={classes.tableDoubleDivDescription}>
                {" "}
                ** Fetches vehicle (VIN) manifests **
              </div>
              <div className={classes.tableDoubleDivJson}>
                {!isLoadingManifest && !emptyFields ? (
                  <pre>{`${prettyCopyJson(manifest)}`}</pre>
                ) : isLoadingManifest && !emptyFields ? (
                  <LoadingSpinner />
                ) : (
                  "No data available"
                )}
              </div>
            </div>
            {/*  */}
            {/*  */}
            {/*  */}
          </div>
        </div>
        {/* ----End CUBE----- */}
        {/* second set of tables */}
        <div
          className={classes.flexContainerRowDoubleTable}
          style={{ userSelect: "text", cursor: "auto" }}
        >
          <div className={classes.flexContainerRowDoubleTable}>
            <div className={classes.doubleTableSet}>
              <div className={classes.tableDoubleDiv}>
                <div className={classes.tableDoubleDivTitle}>
                  <div className={classes.tableDoubleDivTitleText}>
                    getActivationJobsByVehicleId
                  </div>
                  <div className={classes.infoSnackBar}></div>
                  <div className={classes.infoIcon}>
                    <Tooltip
                      title="ServiceActivationJobs are triggered, when Modules are initially activated.
 Further Module updates are triggerd by ModuleRollout- or ModuleUpdate- JobTypes."
                    >
                      <img src={info} alt="" />
                    </Tooltip>
                  </div>
                  <div className={classes.copyIcon}>
                    <CopyToClipboard
                      text={` JSON response: ${prettyCopyJson(vehicleJobs)}
           `}
                    >
                      <img
                        src={copy}
                        alt=""
                        title="Copy vehicle activation jobs on clipboard"
                        onClick={() => console.log("copied")}
                      />
                    </CopyToClipboard>
                  </div>
                  <div className={classes.downloadIcon}>
                    <img
                      src={download}
                      alt=""
                      title="Download vehicle activation jobs response"
                      onClick={() =>
                        downloadHandler(
                          prettyCopyJson(vehicleJobs),
                          "_GetActivationJobsByVehicleId"
                        )
                      }
                    />
                  </div>
                </div>
                <div className={classes.tableDoubleDivDescription}>
                  {" "}
                  ** shows a vehicle's activation jobs **
                </div>
                <div className={classes.tableDoubleDivJson}>
                  {" "}
                  {!isLoadingActivationJobs && !emptyFields ? (
                    <pre>{`${prettyCopyJson(vehicleJobs)}`}</pre>
                  ) : isLoadingActivationJobs && !emptyFields ? (
                    <LoadingSpinner />
                  ) : (
                    "No data available"
                  )}
                </div>
              </div>
              {/* second Table */}
              <div className={classes.tableDoubleDiv}>
                <div className={classes.tableDoubleDivTitle}>
                  <div className={classes.tableDoubleDivTitleText}>
                    {!currentVehicleStatus &&
                    currentStateStatus === "NotFinished"
                      ? "getVehicleCurrentState - Please wait..."
                      : currentStateStatus
                      ? "Request had timed out. Please try again"
                      : "getVehicleCurrentState"}
                  </div>
                  <div className={classes.infoSnackBar}></div>
                  <div className={classes.copyIcon}>
                    <CopyToClipboard
                      text={` JSON response: ${prettyCopyJson(
                        vehicleCurrentState
                      )}
           `}
                    >
                      <img
                        src={copy}
                        alt=""
                        title="Copy vehicle current state on clipboard"
                        onClick={() => console.log("copied")}
                      />
                    </CopyToClipboard>
                  </div>
                  <div className={classes.downloadIcon}>
                    <img
                      src={download}
                      alt=""
                      title="Download vehicle current state response"
                      onClick={() =>
                        downloadHandler(
                          prettyCopyJson(vehicleCurrentState),
                          "_GetVehicleCurrentState"
                        )
                      }
                    />
                  </div>
                </div>
                <div className={classes.tableDoubleDivDescription}>
                  {" "}
                  ** shows a vehicle's current state **
                </div>
                <div className={classes.tableDoubleDivJson}>
                  {" "}
                  {
                    // Show access forbidden message
                    emptyFields ? (
                      "No data available"
                    ) : accessForbidden ? (
                      <div
                        style={{
                          color: "var(--primary)",
                          textAlign: "center",
                          width: "100%",
                          fontSize: "1.25rem",
                        }}
                      >
                        {" "}
                        "Access to resource is forbidden."
                      </div>
                    ) : !isLoadingVehicleCurrentState && !emptyFields ? (
                      <pre>{`${prettyCopyJson(vehicleCurrentState)}`}</pre>
                    ) : isLoadingVehicleCurrentState && !emptyFields ? (
                      <LoadingSpinner />
                    ) : (
                      "No data available"
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <div className={classes.doubleTableSet}>
            <div className={classes.tableDoubleDiv}>
              <div className={classes.tableDoubleDivTitle}>
                <div className={classes.tableDoubleDivTitleText}>
                  getDefaultServiceVehicleUserClaimsOfPrimaryUser
                </div>
                <div className={classes.infoSnackBar}></div>
                <div className={classes.copyIcon}>
                  <CopyToClipboard
                    text={` JSON response: ${prettyCopyJson(primaryUserClaims)}
           `}
                  >
                    <img
                      src={copy}
                      alt=""
                      title="Copy claims on clipboard"
                      onClick={() => console.log("copied")}
                    />
                  </CopyToClipboard>
                </div>
                <div className={classes.downloadIcon}>
                  <img
                    src={download}
                    alt=""
                    title="Download user claims of primary user response"
                    onClick={() =>
                      downloadHandler(
                        prettyCopyJson(primaryUserClaims),
                        "_GetDefaultServiceVehicleUserClaimsOfPrimaryUser"
                      )
                    }
                  />
                </div>
              </div>
              <div className={classes.tableDoubleDivDescription}>
                {" "}
                ** If a primary user exists, this fetches the Service Vehicle
                User Claims **
              </div>
              <div
                className={classes.tableDoubleDivJson}
                style={{ minHeight: "75%" }}
              >
                {" "}
                {!isLoadingUserClaimsOfPrimaryUser && !emptyFields ? (
                  <pre>{`${prettyCopyJson(primaryUserClaims)}`}</pre>
                ) : isLoadingUserClaimsOfPrimaryUser && !emptyFields ? (
                  <LoadingSpinner />
                ) : (
                  "No data available"
                )}
              </div>
            </div>
            {/*  */}
            {/*  */}
            {/*  */}
            <div className={classes.tableDoubleDiv}>
              <div className={classes.tableDoubleDivTitle}>
                {" "}
                <div className={classes.tableDoubleDivTitleText}>
                  getAllVehicleServiceClaims
                </div>
                <div className={classes.infoSnackBar}></div>
                <div className={classes.copyIcon}>
                  <CopyToClipboard
                    text={` JSON response: ${prettyCopyJson(
                      allVehicleServiceClaims
                    )}
           `}
                  >
                    <img
                      src={copy}
                      alt=""
                      title="Copy all vehicle service claims on clipboard"
                      onClick={() => console.log("copied")}
                    />
                  </CopyToClipboard>
                </div>
                <div className={classes.downloadIcon}>
                  <img
                    src={download}
                    alt=""
                    title="Download all vehicle service claims response"
                    onClick={() =>
                      downloadHandler(
                        prettyCopyJson(allVehicleServiceClaims),
                        "_GetAllVehicleServiceClaims"
                      )
                    }
                  />
                </div>
              </div>
              <div className={classes.tableDoubleDivDescription}>
                {" "}
                ** fetches all vehicle (VIN) service claims **
              </div>
              <div className={classes.tableDoubleDivJson}>
                {" "}
                {!isLoadingAllVehicleServiceClaims && !emptyFields ? (
                  <pre>{`${prettyCopyJson(allVehicleServiceClaims)}`}</pre>
                ) : isLoadingAllVehicleServiceClaims && !emptyFields ? (
                  <LoadingSpinner />
                ) : (
                  "No data available"
                )}
              </div>
            </div>
          </div>

          {/*  */}
          {/*  */}
        </div>
      </div>
    </>
  );
}

export default JsonProtocols;
